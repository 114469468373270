export default {
  vehicleSeed: [
    {
      imagePath: 'https://placekitten.com/75/75',
      vehiclePhotoId: 1,
      localId: 1,
    },
    {
      imagePath: 'https://placekitten.com/75/75',
      vehiclePhotoId: 2,
      localId: 2,
    },
    {
      imagePath: null,
      vehiclePhotoId: 3,
      anchor: true,
      localId: 3,
    },
    {
      imagePath: null,
      vehiclePhotoId: 4,
      localId: 4,
    },
    {
      imagePath: null,
      vehiclePhotoId: 5,
      localId: 5,
    },
    {
      imagePath: null,
      vehiclePhotoId: 6,
      localId: 6,
    },
  ],
  vehicleImages: [
    {
      imagePath: null,
      localId: 1,
    },
    {
      imagePath: null,
      localId: 2,
    },
    {
      imagePath: null,
      localId: 3,
    },
    {
      imagePath: null,
      localId: 4,
    },
    {
      imagePath: null,
      localId: 5,
    },
    {
      imagePath: null,
      localId: 6,
    },
  ],
  vehicleItems: [
    {
      vehicleTypeId: '1',
      vehicleTypeName: 'Charter Bus',
      key: 'charter_bus',
      description: null,
    },
    {
      vehicleTypeId: '2',
      vehicleTypeName: 'Mini Bus',
      key: 'mini_bus',
      description: null,
    },
    {
      vehicleTypeId: '3',
      vehicleTypeName: 'Sprinter',
      key: 'sprinter',
      description: 'A Sprinter van or comparable',
    },
    {
      vehicleTypeId: '4',
      vehicleTypeName: 'Party Bus',
      key: 'party_bus',
      description: null,
    },
    {
      vehicleTypeId: '5',
      vehicleTypeName: 'Sedan',
      key: 'sedan',
      description: null,
    },
    {
      vehicleTypeId: '6',
      vehicleTypeName: 'SUV',
      key: 'suv',
      description: null,
    },
    {
      vehicleTypeId: '7',
      vehicleTypeName: 'Limousine',
      key: 'limousine',
      description: null,
    },
    {
      vehicleTypeId: '8',
      vehicleTypeName: 'Trolley',
      key: 'trolley',
      description: null,
    },
  ],
  vehicle: {
    vehicleAmenityDTOs: [
      {
        amenityId: 1,
        isSupported: false,
        amenityName: 'Wifi',
        key: 'wifi',
        description: 'Does the vehicle have Wifi',
      },
      {
        amenityId: 4,
        amenityName: 'Seat Belts',
        key: 'seatbelts',
        description: 'Does the vehicle have seat belts',
        isSupported: false,
      },
      {
        amenityId: 7,
        amenityName: 'Outlets',
        key: 'outlets',
        description: 'Does the vehicle have electrical outlets',
        isSupported: false,
      },
      {
        amenityId: 2,
        amenityName: 'Luggage',
        key: 'luggage',
        description: 'Does the vehicle have luggage storage',
        isSupported: false,
      },
      {
        amenityId: 5,
        amenityName: 'ADA Compliant',
        key: 'ada',
        description: 'Is the vehicle ADA compliant',
        isSupported: false,
      },
      {
        amenityId: 8,
        amenityName: 'Leather Seats',
        key: 'leather_seats',
        description: 'Does the vehicle have leather seats',
        isSupported: false,
      },
      {
        amenityId: 3,
        amenityName: 'Bathroom',
        key: 'lavatory',
        description: 'Does the vehicle have a bathroom',
        isSupported: false,
      },
      {
        amenityId: 6,
        amenityName: 'TV Screens',
        key: 'tv_screens',
        description: 'Does the vehicle have TV screens',
        isSupported: false,
      },
    ],
    garageName: '',
    garageId: '',
    vehicleTypeId: '',
    vehicleTypeName: '',
    id: null,
    name: null,
    primaryImage: '',
    vehicleModel: '',
    vehicleMake: '',
    vehicleYear: '',
    passengerCapacity: null,
    vinNumber: '',
    licensePlate: '',
    is_active: true,
  },
}
