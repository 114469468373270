<template>
  <div>
    <BaseForm
      :id="id"
      ref="form"
      :class="isInSidebarDialog ? 'cr-sidebar-dialog__vehicles-form' : ''"
      :disabled="disabled"
      :form-type="formType"
      :layout="layout"
      :loading="loading"
      :row="row"
      :select-models="selectModels"
      :hide-header="isInSidebarDialog"
    >
      <template v-if="!isInSidebarDialog" #bottom>
        <div class="button-container">
          <v-btn class="btn-secondaryaction" @click="cancelAction">
            {{ isModeView ? 'All Vehicles' : 'Cancel' }}
          </v-btn>
          <v-btn
            v-if="isModeEdit"
            :id="`vehicles-form-button-save-vehicle`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Vehicle
          </v-btn>
          <v-btn
            v-if="isModeAdd"
            :id="`vehicles-form-button-add-vehicle`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Add Vehicle
          </v-btn>
          <v-btn
            v-if="isModeView"
            :id="`vehicles-form-button-enable-edit`"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Vehicle
          </v-btn>
        </div>
      </template>
      <template v-if="!isInSidebarDialog" #vehicleimages>
        <div>
          <label>Vehicle Images (maximum of six)</label>
          <div class="vehicle-image-container">
            <div
              v-for="(image, index) in vehicleImages"
              :key="image.vehiclePhotoId"
              :class="`vehicle-image-box ${
                image.imagePath ? '' : 'add-spacing'
              }`"
            >
              <div v-if="image.anchor" class="border-box solid-black">
                <label class="add-icon">+</label>
                <input
                  :id="`vehicles-form-input-upload-file-${index}`"
                  :disabled="disabled"
                  accept="image/*"
                  class="vehicle-image-input"
                  multiple
                  type="file"
                  @change="uploadFile"
                />
              </div>
              <div v-if="image.imagePath">
                <div class="img-options">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon
                        :id="`vehicles-form-icon-vehicle-primary-image-${index}`"
                        :class="`star-icon ${
                          image.primaryImage
                            ? 'chosen'
                            : `${JSON.stringify(image)}`
                        }`"
                        v-on="on"
                        @click="(evt) => toggleDefaultImg(image)"
                      >
                        stars
                      </v-icon>
                    </template>
                    <span>Make photo default.</span>
                  </v-tooltip>
                  <v-tooltip v-if="isModeEdit" top>
                    <template #activator="{ on }">
                      <v-icon
                        :id="`vehicles-form-button-remove-image-${index}`"
                        class="remove-icon"
                        v-on="on"
                        @click="
                          (evt) => {
                            removeImage(image)
                          }
                        "
                      >
                        remove_circle_outline
                      </v-icon>
                    </template>
                    <span>Remove photo.</span>
                  </v-tooltip>
                </div>
                <img :src="image.imagePath" class="border-box solid-blue" />
              </div>
              <div
                v-if="!image.anchor && !image.imagePath"
                class="border-box dotted"
              />
            </div>
          </div>
        </div>
      </template>
    </BaseForm>
    <template v-if="isInSidebarDialog">
      <div class="cr-sidebar-dialog--button-spacer"></div>
      <CRButton
        class="vehicles-form--action-btn"
        color="primary"
        :height="71"
        @click="submit"
      >
        {{ isModeAdd ? 'Add Vehicle' : 'Save Vehicle' }}
      </CRButton>
    </template>
  </div>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import VehicleSchema from '@/components/VehicleSchema'
import garages from '@/services/garages'
import { authComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'
import { isNotEmpty, isRequired } from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

export default {
  name: 'VehicleForm',
  components: {
    BaseForm,
  },
  metaInfo() {
    return {
      title: 'Vehicle',
    }
  },
  inject: {
    isInSidebarDialog: {
      default: false,
    },
  },
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
    companyId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      vehicleImagesToUpdate: {},
      vehicleImages: [],
      vehicleTypes: [],
      loading: false,
      originalVeh: null,
      originalGar: null,
      formType: '',
      selectModels: {},
      layout: [],
      row: {},
      disabled: true,
      isVinOverrideEnabled: false
    }
  },
  computed: {
    ...authComputed,
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  async mounted() {
    this.isVinOverrideEnabled = await this.isFeatureEnabled(SplitFeatureFlag.VehicleVinOverride)
    this.disabled = this.isModeView

    let vehicleData

    if (!this.isModeAdd) {
      vehicleData = await this.$store
        .dispatch('vehicles/getVehicle', this.id)
        .catch(() => ({}))
    } else {
      vehicleData = { data: JSON.parse(JSON.stringify(VehicleSchema.vehicle)) }
    }
    const defaultImageArray = VehicleSchema.vehicleImages
    let vehicleImages = vehicleData?.data?.vehicle?.vehiclePhotoDTOs || []

    const noDupes = {}
    const lastSix =
      (vehicleImages || []).length >= 6 ? vehicleImages.length - 6 : 0
    const defaultImg = vehicleImages.find((img) => img.primaryImage)
      ? [vehicleImages.find((img) => img.primaryImage)]
      : []
    vehicleImages = [...defaultImg, ...vehicleImages.slice(lastSix)].filter(
      (img) => {
        if (noDupes[img.vehiclePhotoId]) {
          return false
        }

        noDupes[img.vehiclePhotoId] = true
        return true
      }
    )

    this.vehicleImages = defaultImageArray.reduce(
      (accumulator, defaultImage, index) => {
        const targetImage = vehicleImages[index]
          ? vehicleImages[index]
          : defaultImage

        if (!targetImage.imagePath && !accumulator.anchor) {
          targetImage.anchor = true
          accumulator.anchor = true
        }

        if (targetImage.imagePath) {
          targetImage.imagePath = `https://${baseUrl(null)}${
            targetImage.imagePath
          }`
        }

        targetImage.localId = index
        accumulator.collection.push(targetImage)
        return accumulator
      },
      { anchor: false, collection: [] }
    ).collection

    if (this.vehicleImages.length > 0) {
      this.setPrimaryImage()
    }

    const garageDataCount = await garages
      .getGarages({})
      .then((dat) => {
        return dat.data.count
      })
      .catch(() => {})

    const garageData = await garages
      .getGarages({
        pageSize: garageDataCount || 100,
        page: 1,
      })
      .catch(() => {})

    const garageResults = garageData?.data?.resultList || []

    const unpackedVehicle = vehicleData?.data?.vehicle || {}
    this.originalVeh = JSON.parse(JSON.stringify(unpackedVehicle))
    this.originalGar = JSON.parse(JSON.stringify(garageResults))
    this.row = unpackedVehicle

    if (this.isModeAdd) {
      this.formType = 'Add Vehicle'
    }

    if (this.isModeView) {
      this.formType = `${this.row.vehicleName}`
    }

    if (this.isModeEdit) {
      this.formType = `Edit: ${this.row.vehicleName}`
    }

    this.selectModels.selectedGarage = garageResults.reduce(
      (accumulator, garage) => {
        if (garage.garageId === this.row.garageId) {
          return garage
        }

        return accumulator
      },
      null
    )

    const vehicleTypeResponse = await this.$store.dispatch(
      'types/getVehicleTypes'
    )
    const vehicleTypes = vehicleTypeResponse.data.resultList
    vehicleTypes.forEach((vehicleType) => {
      vehicleType.vehicleTypeId = vehicleType.id
      vehicleType.vehicleTypeName = vehicleType.label
      delete vehicleType.active
      delete vehicleType.companyId
      delete vehicleType.id
      delete vehicleType.label
    })
    this.vehicleTypes = vehicleTypes

    this.selectModels.selectedVehicle = this.vehicleTypes.find(
      (vehicleType) => vehicleType.vehicleTypeId == this.row.vehicleTypeId
    )

    this.buildBaseFormLayout(garageResults)
  },
  updated() {
    if (this.row) {
      if (this.isModeAdd) {
        this.formType = 'Add Vehicle'
      }

      if (this.isModeView) {
        this.formType = `${this.row.vehicleName}`
      }

      if (this.isModeEdit) {
        this.formType = `Edit: ${this.row.vehicleName}`
      }
    }
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    buildBaseFormLayout(garages) {
      let layout = []

      if (!this.isInSidebarDialog) {
        layout = [
          {
            id: 1,
            fields: [
              {
                key: 'vehicleName',
                label: 'Vehicle Name',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Name Required',
                    error: 'Vehicle Name Required',
                  }),
                ],
              },
              {
                key: 'vehicleMake',
                label: 'Vehicle Make',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Number Required',
                    error: 'Vehicle Number Required',
                  }),
                ],
              },
              {
                key: 'vehicleYear',
                label: 'Vehicle Year',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Year Required',
                    error: 'Vehicle Year Required',
                  }),
                ],
              },
              {
                key: 'vinNumber',
                label: 'VIN #',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'VIN Required',
                    error: 'VIN Required',
                  }),
                ],
              },
              {
                key: 'vinOverride',
                model: 'vinOverride',
                label: 'Enable VIN Override',
                type: 'checkbox',
                meta: true,
                toggle: this.isVinOverrideEnabled,
              },
              {
                key: 'garage',
                label: 'Garage',
                type: 'select',
                text: 'garageName',
                id: 'garageId',
                modelName: 'selectedGarage',
                model: this.selectModels.selectedGarage,
                items: garages,
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Garage Required',
                    error: 'Garage Required',
                  }),
                ],
              },
              {
                type: 'check-field',
                label: 'vehicle-amenities',
                items: this.row.vehicleAmenityDTOs,
                key: 'vehicleAmenityDTOs',
                checkKey: 'key',
                checkModel: 'isSupported',
                checkLabel: 'amenityName',
              },
            ],
          },
          {
            id: 2,
            fields: [
              {
                key: 'vehicleType',
                label: 'Vehicle Type',
                type: 'select',
                text: 'vehicleTypeName',
                id: 'vehicleTypeId',
                modelName: 'selectedVehicle',
                model: this.selectModels.selectedVehicle,
                items: this.vehicleTypes,
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Type Required',
                    error: 'Vehicle Type Required',
                  }),
                ],
              },
              {
                key: 'vehicleModel',
                label: 'Vehicle Model',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Model Required',
                    error: 'Vehicle Model Required',
                  }),
                ],
              },
              {
                key: 'passengerCapacity',
                label: 'Capacity',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Capacity Required',
                    error: 'Capacity Required',
                  }),
                ],
              },
              {
                key: 'licensePlate',
                label: 'License Plate',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'License Plate Required',
                    error: 'License Plate Required',
                  }),
                ],
              },
              {
                type: 'slot',
                label: 'vehicleimages',
              },
            ],
          },
        ]
      } else {
        layout = [
          {
            id: 1,
            fields: [
              {
                key: 'vehicleType',
                label: 'Vehicle Type',
                type: 'select',
                text: 'vehicleTypeName',
                id: 'vehicleTypeId',
                modelName: 'selectedVehicle',
                model: this.selectModels.selectedVehicle,
                items: this.vehicleTypes,
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Type Required',
                    error: 'Vehicle Type Required',
                  }),
                ],
              },
              {
                key: 'vehicleName',
                label: 'Vehicle Name',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Name Required',
                    error: 'Vehicle Name Required',
                  }),
                ],
              },
              {
                key: 'vehicleMake',
                label: 'Vehicle Make',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Number Required',
                    error: 'Vehicle Number Required',
                  }),
                ],
              },
              {
                key: 'vehicleYear',
                label: 'Vehicle Year',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Year Required',
                    error: 'Vehicle Year Required',
                  }),
                ],
              },
              {
                key: 'vinNumber',
                label: 'VIN #',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'VIN Required',
                    error: 'VIN Required',
                  }),
                ],
              },
              {
                key: 'vinOverride',
                model: 'vinOverride',
                label: 'Enable VIN Override',
                type: 'checkbox',
                meta: true,
                toggle: this.isVinOverrideEnabled,
              },
              {
                key: 'vehicleModel',
                label: 'Vehicle Model',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Model Required',
                    error: 'Vehicle Model Required',
                  }),
                ],
              },
              {
                key: 'passengerCapacity',
                label: 'Capacity',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'Capacity Required',
                    error: 'Capacity Required',
                  }),
                ],
              },
              {
                key: 'licensePlate',
                label: 'License Plate',
                type: 'text',
                rules: [
                  isRequired(true, isNotEmpty, {
                    req: 'License Plate Required',
                    error: 'License Plate Required',
                  }),
                ],
              },
            ],
          },
        ]
      }

      this.layout = layout
    },
    toggleDefaultImg(img) {
      this.vehicleImages.forEach((photo) => (photo.primaryImage = false))
      img.primaryImage = true
      this.vehicleImages = [...this.vehicleImages]
      this.row.primaryImage = img.name || img.id
    },
    setPrimaryImage() {
      const noPrimarySet = this.vehicleImages.every(
        (photo) => !photo.primaryImage
      )
      if (noPrimarySet) {
        this.toggleDefaultImg(this.vehicleImages[0])
      }
    },
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    disableAndRevert() {
      this.disabled = true
      this.mode = 'view'
      this.row = this.originalVeh

      this.selectedGarage = this.originalGar.reduce((accumulator, garages) => {
        if (garages.garageId === this.row.garageId) {
          return garages
        }

        return accumulator
      }, null)

      this.selectedVehicle = this.vehicleTypes.reduce(
        (accumulator, vehicle) => {
          if (vehicle.vehicleTypeId === this.row.vehicleTypeId) {
            return vehicle
          }

          return accumulator
        },
        null
      )

      this.$router.push(`/vehicles/view/${this.id}`)
    },
    async submit() {
      this.loading = true
      const test = this.$refs.form.validateAll()
      if (test.length > 0) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be required or are invalid.',
          true
        )
        return
      }

      try {
        const submission = JSON.parse(JSON.stringify(this.row))
        if (!this.isInSidebarDialog) {
          const targetGarage = await garages.getGarage(
            this.selectModels.selectedGarage.garageId
          )
          submission.garageId = this.selectModels.selectedGarage.garageId
          submission.garageName = targetGarage.data.garageName
        }

        const user = this.currentUser
        this.row.company = user.company

        submission.vehicleTypeId = this.selectModels.selectedVehicle.vehicleTypeId
        submission.vehicleTypeName = this.selectModels.selectedVehicle.vehicleTypeName
        submission.companyId = this.companyId || this.row.company.companyId
        const action = this.isModeAdd
          ? 'vehicles/addVehicle'
          : 'vehicles/modifyVehicle'

        const updatedVehicle = await this.$store.dispatch(action, {
          id: this.id,
          payload: submission,
        })

        if (this.vehicleImages) {
          const imageIdsToRemove = Object.values(this.vehicleImagesToUpdate)
            .filter((photo) => photo.op === 'remove' && !!photo.vehiclePhotoId)
            .map(({ vehiclePhotoId }) => ( vehiclePhotoId ))

          if (imageIdsToRemove?.length) {
            const removalPayload = {
              vehiclePhotoIds: imageIdsToRemove,
            }

            await this.$store
              .dispatch('vehicles/removeVehiclePhotos', {
                payload: removalPayload,
                vehicleId: this.id || updatedVehicle.data,
              })
              .catch(() => ({}))
          }

          await Promise.all(
            Object.keys(this.vehicleImagesToUpdate)
              .filter((key) => this.vehicleImagesToUpdate[key].op === 'add')
              .map((key) => this.vehicleImagesToUpdate[key].file)
              .map((file) => {
                const form = new FormData()
                form.append('file', file)

                this.$store
                  .dispatch('vehicles/uploadVehiclePhotos', {
                    payload: form,
                    vehicleId: this.id || updatedVehicle.data,
                  })
                  .catch((e) => {
                    console.log(e)
                  })
              })
          )
        }
      } catch (e) {
        this.loading = false
        this.disabled = !this.disabled
        this.displayWarning = true
        this.warningMessage = e
        return
      }

      this.loading = false

      if (!this.isInSidebarDialog) {
        this.$router.push(`/vehicles${this.id ? `/view/${this.id}` : ''}`)
        this.$store.dispatch('app/showAlert', {
          message: 'Vehicle info saved successfully.',
          type: 'success',
        })
      } else {
        this.$store.dispatch('app/closeDialog')
        EventBus.$emit('successful-add-vehicle')
        EventBus.$emit('global-table-view-refresh')
        this.$nextTick(() =>
          this.$store.dispatch('app/showAlert', {
            message: this.isModeAdd ? 'Vehicle successfully added.' : 'Vehicle info saved successfully.',
          })
        )
      }

      this.disabled = !this.disabled
      this.vehicleImagesToUpdate = []
      if (!this.isModeAdd) {
        const vehicleData = await this.$store
          .dispatch('vehicles/getVehicle', this.id)
          .catch(() => ({}))
      }
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.mode = 'edit'
        this.$router.push(`/vehicles/edit/${this.id}`)
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push('/vehicles')
    },
    removeImage(target) {
      this.vehicleImagesToUpdate[target.localId] = {
        op: 'remove',
        vehiclePhotoId: target.vehiclePhotoId || null,
      }
      const blank = {
        imagePath: null,
        localId: target.localId,
      }

      const changes = this.vehicleImages.filter(
        (img) => img.localId !== target.localId
      )

      changes.push(blank)

      changes.forEach((el) => delete el.anchor)

      const newAnchor = changes.find((img) => img.imagePath === null)

      newAnchor.anchor = true

      this.vehicleImages = changes
    },
    async uploadFile(evt) {
      const changes = Array.from(evt.target.files).map((file, idx) => {
        const link = URL.createObjectURL(file)
        const target = this.vehicleImages.find((img) => img.imagePath === null)
        const newImage = JSON.parse(JSON.stringify(target))

        newImage.imagePath = link

        this.vehicleImagesToUpdate[target.localId] = {
          file,
          op: 'add',
          name: new Date().getTime() + idx + file.name,
          vehiclePhotoId: newImage.vehiclePhotoId || null,
        }

        delete newImage.anchor
        return newImage
      })

      const appliedChanges = this.vehicleImages.map((img) => {
        const target = changes.find((change) => change.localId === img.localId)
        if (target) {
          return target
        }

        delete img.anchor
        return img
      })

      const placeAnchor = appliedChanges.find((img) => img.imagePath === null)
      if (placeAnchor) {
        placeAnchor.anchor = true
      }

      this.vehicleImages = appliedChanges
      this.setPrimaryImage()
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-sidebar-dialog__vehicles-form {
  ::v-deep .v-text-field .v-input__slot {
    background-color: #f5f7fb !important;
    border: 1px solid #eaebee !important;
  }

  ::v-deep .form-container .width-30 {
    max-width: unset;
  }
}

.vehicles-form--action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  background: $primary;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  border-radius: 0 !important;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  width: inherit;
}

.button-container {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-right: 3%;
}

.button-container > * {
  margin-right: 10%;
}

.border-box {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  border-radius: 10px;

  &.solid-blue {
    height: 68px;
    border: 1px solid $blue;
    object-fit: cover;
  }

  &.solid-black {
    border: 1px solid $black;
  }

  &.dotted {
    border: 1px dotted lightblue;
  }

  .add-icon {
    width: 100%;
    font-size: 5em;
    font-weight: 200;
    line-height: 50px;
    text-align: center;
  }
}

.vehicle-image-box {
  width: 65px;
  height: 85px;
  margin-right: 4%;

  &.add-spacing {
    width: 65px;
    height: 65px;
    margin-top: 4%;
  }
}

.star-icon {
  display: inherit;
  height: 20px;
  font-size: 20px;
  color: $primary !important;
  background-color: $white;
  border-radius: 15px;

  &.chosen {
    color: goldenrod !important;
  }
}

.remove-icon {
  height: 20px;
  font-size: 20px;
  color: $white;
  cursor: pointer;
  background-color: $red;
  border-radius: 10px;
}

.vehicle-image-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.vehicle-image-input {
  position: absolute;
  width: 500px;
  height: 75px;
  cursor: pointer;
  opacity: 0;
}

.img-options {
  position: relative;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 102px;
}

::v-deep .form-container {
  padding-top: 1%;
}

::v-deep .input-space {
  margin-bottom: 5%;
}

::v-deep .width-30 {
  max-width: 421px;
}

::v-deep .checkbox i {
  font-size: 14px;
  line-height: 1.85;
}

::v-deep .checkbox.v-label {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  ::v-deep .amenities {
    display: grid;
    grid-template-rows: repeat(5, 35px);
    grid-template-columns: repeat(2, minmax(140px, 150px));
  }
}

@media screen and (min-width: 600px) and(max-width: 920px) {
  ::v-deep .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
  ::v-deep .vehicle-image-container {
    min-width: 550px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  ::v-deep .width-30 {
    max-width: 300px;
  }
}

@media screen and (min-width: 1400px) {
  ::v-deep .width-30 {
    max-width: 500px;
  }
  ::v-deep .vehicle-image-container {
    min-width: 550px;
  }
}

@media screen and (max-width: 600px) {
  ::v-deep .width-30:nth-child(1) {
    margin-top: 100px;
  }
}
</style>
