var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseForm',{ref:"form",class:_vm.isInSidebarDialog ? 'cr-sidebar-dialog__vehicles-form' : '',attrs:{"id":_vm.id,"disabled":_vm.disabled,"form-type":_vm.formType,"layout":_vm.layout,"loading":_vm.loading,"row":_vm.row,"select-models":_vm.selectModels,"hide-header":_vm.isInSidebarDialog},scopedSlots:_vm._u([(!_vm.isInSidebarDialog)?{key:"bottom",fn:function(){return [_c('div',{staticClass:"button-container"},[_c('v-btn',{staticClass:"btn-secondaryaction",on:{"click":_vm.cancelAction}},[_vm._v(" "+_vm._s(_vm.isModeView ? 'All Vehicles' : 'Cancel')+" ")]),(_vm.isModeEdit)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`vehicles-form-button-save-vehicle`,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Save Vehicle ")]):_vm._e(),(_vm.isModeAdd)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`vehicles-form-button-add-vehicle`,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Add Vehicle ")]):_vm._e(),(_vm.isModeView)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`vehicles-form-button-enable-edit`},on:{"click":_vm.enableEdit}},[_vm._v(" Edit Vehicle ")]):_vm._e()],1)]},proxy:true}:null,(!_vm.isInSidebarDialog)?{key:"vehicleimages",fn:function(){return [_c('div',[_c('label',[_vm._v("Vehicle Images (maximum of six)")]),_c('div',{staticClass:"vehicle-image-container"},_vm._l((_vm.vehicleImages),function(image,index){return _c('div',{key:image.vehiclePhotoId,class:`vehicle-image-box ${
              image.imagePath ? '' : 'add-spacing'
            }`},[(image.anchor)?_c('div',{staticClass:"border-box solid-black"},[_c('label',{staticClass:"add-icon"},[_vm._v("+")]),_c('input',{staticClass:"vehicle-image-input",attrs:{"id":`vehicles-form-input-upload-file-${index}`,"disabled":_vm.disabled,"accept":"image/*","multiple":"","type":"file"},on:{"change":_vm.uploadFile}})]):_vm._e(),(image.imagePath)?_c('div',[_c('div',{staticClass:"img-options"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({class:`star-icon ${
                        image.primaryImage
                          ? 'chosen'
                          : `${JSON.stringify(image)}`
                      }`,attrs:{"id":`vehicles-form-icon-vehicle-primary-image-${index}`},on:{"click":(evt) => _vm.toggleDefaultImg(image)}},on),[_vm._v(" stars ")])]}}],null,true)},[_c('span',[_vm._v("Make photo default.")])]),(_vm.isModeEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"remove-icon",attrs:{"id":`vehicles-form-button-remove-image-${index}`},on:{"click":(evt) => {
                          _vm.removeImage(image)
                        }}},on),[_vm._v(" remove_circle_outline ")])]}}],null,true)},[_c('span',[_vm._v("Remove photo.")])]):_vm._e()],1),_c('img',{staticClass:"border-box solid-blue",attrs:{"src":image.imagePath}})]):_vm._e(),(!image.anchor && !image.imagePath)?_c('div',{staticClass:"border-box dotted"}):_vm._e()])}),0)])]},proxy:true}:null],null,true)}),(_vm.isInSidebarDialog)?[_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('CRButton',{staticClass:"vehicles-form--action-btn",attrs:{"color":"primary","height":71},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.isModeAdd ? 'Add Vehicle' : 'Save Vehicle')+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }